import { useState } from "react";
import { Filter } from "@winclap-platform/ui/components/filter";
import { useAppOptions, useReports } from "~/hooks/reports/api";
import { BaseReport } from "@winclap-platform/models/schemas/reports";
import { ComboBox } from "@winclap-platform/ui/components/combobox";
import { ReportsNavigation } from "~/components/copilot/reports/reports-navigation";
import { DatePicker } from "@winclap-platform/ui/components/datepicker";
import { endOfWeek, subDays } from "date-fns";
import { ReportsTable } from "~/components/tables/reports-table";

const reportTypeOptions = [
  { label: "WoW", value: "wow_report" },
  { label: "UAC", value: "google_report" },
  { label: "Creatives", value: "creative_report" },
];

const appOsOptions = [
  { label: "iOS", value: "iOS" },
  { label: "Android", value: "Android" },
];

const dateOptions = [
  { label: "1W", value: "1W" },
  { label: "2W", value: "2W" },
  { label: "1M", value: "1M" },
];

const today = new Date();
today.setUTCHours(0, 0, 0, 0);

const filterDateRanges: Record<string, [Date, Date]> = {
  "1W": [subDays(today, 8), today],
  "2W": [subDays(today, 15), today],
  "1M": [subDays(today, 32), today],
};

export default function Copilot() {
  const [reportType, setReportType] =
    useState<BaseReport["report_type"]>("wow_report");
  const [appOs, setAppOs] = useState("");
  const [appId, setAppId] = useState("");
  const [filterDate, setFilterDate] = useState("2W");
  const [pickerDate, setPickerDate] = useState<Date>();
  const { data: appOptions, isPending: isLoadingApps } = useAppOptions(reportType);

  const handleDatePickerChange = (date?: Date) => {
    setFilterDate("");
    setPickerDate(date);
  };

  const handleDateFiltersChange = (value: string) => {
    setPickerDate(undefined);
    setFilterDate(value);
  };

  const dateRange: [Date, Date] | undefined = pickerDate
    ? [pickerDate, endOfWeek(pickerDate, { weekStartsOn: 1 })]
    : filterDate
      ? filterDateRanges[filterDate]
      : undefined;

  const { data, isPending } = useReports({
    reportType,
    appId,
    dateRange,
    appOs,
  });

  return (
    <main className="flex w-full flex-col p-6 py-10">
      <ReportsNavigation />
      <section className="border-primary-60 mb-4 flex flex-wrap items-center gap-5 rounded border bg-white p-4">
        <h1 className="font-judson text-2xl font-semibold">Reports</h1>
        <Filter
          value={reportType}
          options={reportTypeOptions}
          type="single"
          onValueChange={(value) =>
            value && setReportType(value as BaseReport["report_type"])
          }
        />
        <Filter
          value={appOs}
          options={appOsOptions}
          type="single"
          onValueChange={setAppOs}
        />
        <ComboBox
          values={[appId]}
          onChange={setAppId}
          placeholder="App ID"
          loading={isLoadingApps}
          className="w-48"
          disabled={!appOptions?.length}
          options={appOptions}
        />
        <DatePicker
          label="Week"
          className="w-48"
          mode="week"
          value={pickerDate}
          onChange={handleDatePickerChange}
        />
        <Filter
          value={filterDate}
          options={dateOptions}
          type="single"
          onValueChange={handleDateFiltersChange}
        />
      </section>
      <ReportsTable data={data?.reports || []} isLoading={isPending} />
    </main>
  );
}
