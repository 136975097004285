import * as React from "react";
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";
import { reportsColumns } from "./reports-columns";
import { BaseReport } from "@winclap-platform/models/schemas/reports";
import { TableLayout } from "./table-layout";

export const ReportsTable = ({
  data,
  isLoading,
}: {
  data: BaseReport[];
  isLoading?: boolean;
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: "week_date",
      desc: true,
    },
  ]);

  const table = useReactTable({
    data,
    columns: reportsColumns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        // Calculate the page size based on the available space.
        pageSize: Math.floor((window.innerHeight - 268) / 53) - 1,
      },
    },
    state: {
      sorting,
    },
  });

  return <TableLayout table={table} isLoading={isLoading} />;
};
